<script setup>
import PartFormSelect from '@/components/admin/utilities/partFormSelect.vue';

const emit = defineEmits(['removeItem']);
const props = defineProps({
    item: Object,
    index: [String, Number],
    partsUrl: String,
});

function updateRrp(e) {
    if (props.item.rrp !== 0 && e.unit_price) {
        props.item.rrp = e.unit_price;
    }
}
</script>

<template>
    <tr>
        <td style="max-width: 200px;">
            <input type="hidden"
                   :name="`items[${index}][id]`"
                   :value="item.id" />
            <PartFormSelect
                :name="`items[${index}][part]`"
                v-model="item.part"
                @update:model-value="updateRrp"
            />
        </td>
        <td class="text-center quantity">
            <input class="form-control text-center m-auto qty-input"
                   type="text"
                   inputmode="numeric"
                   pattern="[0-9]*"
                   placeholder="1"
                   :name="`items[${index}][quantity]`"
                   v-model="item.quantity" />
        </td>
        <td class="text-center">
            <div class="input-group">
                <span class="input-group-text">
                    <i class="fa fa-gbp"></i>
                </span>
                <input class="form-control"
                       type="text"
                       inputmode="numeric"
                       pattern="[0-9]*"
                       placeholder="0.00"
                       :name="`items[${index}][rrp]`"
                       v-model.number="item.rrp" />
            </div>
        </td>
        <td class="text-center">
            <div class="input-group">
                <input class="form-control"
                       type="text"
                       inputmode="numeric"
                       pattern="[0-9]*"
                       placeholder="0"
                       :name="`items[${index}][discount]`"
                       v-model.number="item.discount" />
                <span class="input-group-text">
                    <i class="fa fa-percent"></i>
                </span>
            </div>
        </td>
        <td>
            <i class="fas fa-times text-danger"
               role="button"
               @click="emit('removeItem', index)"></i>
        </td>
    </tr>
</template>
