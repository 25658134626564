<script setup>
import Datatable from '@/components/admin/utilities/datatable.vue';
import SortableTh from '@/components/admin/datatable/sortableTh.vue';
import { ref } from "vue";

const props = defineProps({
    ajaxUrl: String,
});

const orderBy = ref('name');
const orderAsc = ref(true);
const limit = ref(10);
const searchTerm = ref(null);


const setOrder = function (input) {
    if (orderBy.value == input) {
        orderAsc.value = !orderAsc.value;
    } else {
        orderBy.value = input;
        orderAsc.value = true;
    }
};
</script>

<template>
    <div class="row mb-4 datatable-options">
        <div class="col-md-2">
            <select class="form-select"
                    v-model.number="limit">
                <option>10</option>
                <option>25</option>
                <option>50</option>
            </select>
        </div>
        <div class="col ms-auto mt-2 mt-md-0">
            <div class="input-group">
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       placeholder="Search all kits..">
                <span class="input-group-text bg-body">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
    </div>

    <Datatable :url="ajaxUrl"
               :limit="limit"
               :orderBy="orderBy"
               :orderAsc="orderAsc"
               :search="searchTerm"
               refreshOn="kits-list:refresh">
        <template #headings>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="name">Name</SortableTh>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="sku">SKU</SortableTh>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="is_active"
                        class="text-center">Active</SortableTh>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="retail_price">Price</SortableTh>
            <SortableTh v-on:set-order-by="setOrder"
                        :currentOrderBy="orderBy"
                        :orderAsc="orderAsc"
                        orderBy="components">Components</SortableTh>
            <th></th>
        </template>
        <template #rows="{ data }">
            <tr v-for="kit in data"
                :key="kit.id">
                <td>{{ kit.name }}</td>
                <td>{{ kit.sku }}</td>
                <td class="text-center">
                    <template v-if="kit.is_active">
                        <i class="fa fa-check text-success"></i>
                    </template>
                    <template v-else>
                        <i class="fa fa-times"></i>
                    </template>
                </td>
                <td>{{ kit.retail_price }}</td>
                <td>
                    <ul class="m-0 ps-3">
                        <template v-if="kit.components.length > 5">
                            <li v-for="kitComponent in kit.components.slice(0, 4)">{{ kitComponent }}</li>
                            <li>{{ kit.components.length - 4 }} more...</li>
                        </template>
                        <template v-else>
                            <li v-for="kitComponent in kit.components">{{ kitComponent }}</li>
                        </template>
                    </ul>
                </td>
                <td v-html="kit.actions"></td>
            </tr>
        </template>
    </Datatable>
</template>
