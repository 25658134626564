<script setup>
import { computed, ref } from "vue";
import FormSelect from '@/components/admin/utilities/formSelect.vue';
import { priceFormat } from "@/utilities/priceFormat.js";

const emit = defineEmits(['softDelete', 'removeItem', 'update:unitNet']);
const props = defineProps({
    item: Object,
    index: [String, Number],
    partsUrl: String,
    taxRates: Object,
    currency: {
        type: Object,
        default: () => ({ code: 'GBP', rate: 1, symbol: '£' }),
        validator: (propValue) => {
            return propValue.hasOwnProperty('code') && propValue.hasOwnProperty('rate') && propValue.hasOwnProperty('symbol');
        },
    },
    isInternational: {
        type: Boolean,
        default: false,
    },
});

const partPlaceholderImage = window.placeholderImage;
const isSoftDeleted = ref(false);

const unitNet = computed({
    get: () => props.item.unit_net,
    set: (newValue) => {
        newValue = newValue ? parseFloat(newValue) : 0;
        if (isNaN(newValue) || newValue < 0) {
            unitNet.value = 0;
            return;
        }
        emit('update:unitNet', newValue);
    }
});

const defaultTaxRate = computed(() => {
    if (props.isInternational) {
        const taxRate = props.taxRates.findIndex(rate => rate.rate === "0.00");
        return taxRate >= 0 ? taxRate : 0;
    }

    return 0;
});

if (props.item.is_delivery) {
    unitNet.value *= props.currency.rate;
}

const lineNet = computed(() => priceFormat((unitNet.value * props.item.quantity).toFixed(2)));

const toggleSoftDelete = function () {
    isSoftDeleted.value = !isSoftDeleted.value;
    emit('softDelete', props.index, isSoftDeleted.value);
}
</script>

<template>
    <tr :class="{ 'soft-deleted': isSoftDeleted }">
        <template v-if="item.kit_quote_item">
            <td colspan="999">
                <div class="d-flex gap-3 ps-4">
                    <img class="img-thumbnail object-fit-cover"
                         style="width: 2.5rem; height: 2.5rem;"
                         :src="item.image ? item.image : partPlaceholderImage" />
                    <div class="d-flex flex-column gap-1">
                        <span>{{ item.part.label ? item.part.label : item.part }}</span>
                        <span>Qty Per Kit: {{ item.quantity }}</span>
                        <div v-if="item.extra_message"
                             v-html="item.extra_message"></div>
                    </div>
                </div>
            </td>
        </template>
        <template v-else>
            <td style="max-width: 200px;">
                <input type="hidden"
                       :name="`items[${index}][id]`"
                       :value="item.id" />
                <span v-if="item.is_delivery">Delivery Charge</span>
                <div v-else
                     class="d-flex gap-2">
                    <img class="img-thumbnail object-fit-cover"
                         style="width: 2.5rem; height: 2.5rem;"
                         :src="item.image ? item.image : partPlaceholderImage" alt="Part Image" />
                    <div>
                        <span>{{ item.part.label ? item.part.label : item.part }}</span>
                        <div v-if="item.extra_message"
                             v-html="item.extra_message"></div>
                    </div>
                </div>
                <slot name="item-description"></slot>
            </td>
            <td class="text-center quantity">
                <span v-if="item.is_delivery">1</span>
                <input v-else
                       class="form-control text-center m-auto qty-input"
                       type="text"
                       inputmode="numeric"
                       pattern="[0-9]*"
                       placeholder="1"
                       :name="`items[${index}][quantity]`"
                       v-model="item.quantity" />
            </td>
            <td style="min-width: 175px">
                <div class="input-group">
                    <span class="input-group-text">
                        <i v-if="['EUR', 'USD', 'GBP'].includes(currency.code)"
                           class="fas"
                           :class="{ 'fa-euro-sign': currency.code === 'EUR', 'fa-dollar-sign': currency.code === 'USD', 'fa-pound-sign': currency.code === 'GBP' }">
                        </i>
                        <template v-else>
                            {{ currency.code }}
                        </template>
                    </span>
                    <input class="form-control"
                           type="text"
                           :name="`items[${index}][unit_net]`"
                           inputmode="numeric"
                           pattern="\d+(\.\d{1,2})?"
                           title="Price in pounds, e.g. 95.50"
                           placeholder="0"
                           v-model.number="unitNet">
                </div>
            </td>
            <td style="max-width: 200px;">
                <FormSelect :name="`items[${index}][tax]`"
                            v-model="item.tax"
                            :value="taxRates[defaultTaxRate]"
                            :options="taxRates"
                            placeholder="Select tax rate"
                />
            </td>
            <td class="text-end">{{ currency.symbol }}{{ lineNet }}</td>
            <td>
                <i v-if="item.is_delivery"
                   class="fas fa-times text-danger"
                   role="button"
                   @click="emit('removeItem', index)"></i>
                <template v-else>
                    <i v-if="isSoftDeleted"
                       class="fa fa-arrow-rotate-left revert-soft-delete"
                       role="button"
                       style="width: 10.5px;"
                       @click="toggleSoftDelete"></i>
                    <i v-else
                       class="fas fa-times text-danger"
                       role="button"
                       @click="toggleSoftDelete"></i>
                </template>
            </td>
        </template>
    </tr>
</template>
